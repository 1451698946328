import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import DescriptionComponent from './descriptionComponent';
import servicesData from '../../../servicesData.json';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 990;
`;

const ModalContainer = styled.div`
  position: relative; /* Ajout */
  width: 60vw;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 7vw;
  margin-top: 3vh;
  font-family: "Abel", sans-serif;
  border-radius: 5px;
  font-size: 1.3em;
  transition: opacity 0.8s ease, transform 1.2s ease;
  @media(max-width: 767px){    
    width: 100vw;
    padding: 0;
    font-size: 1em;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
  color: black;
  font-size: 1.8rem;
  cursor: pointer;
  transition: color 0.2s;
  z-index: 999;
`;

const Title = styled.h3`
  text-shadow: 3px 3px 10px #f8cfd1;
`;

const ServiceDetail = () => {
    const { title } = useParams();
    const navigate = useNavigate();
    const [service, setService] = useState(null);

    useEffect(() => {
        const selectedService = servicesData.find(item => item.title === title);
        setService(selectedService);
    }, [title]);

    const handleClose = () => {
        navigate(-1);
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            handleClose();
        }
    };

    if (!service) {
        return <div>Loading...</div>;
    }

    return (
        <ModalOverlay onClick={handleOverlayClick}>
            <ModalContainer>
                <CloseButton onClick={handleClose}>X</CloseButton>
                <Title>{service.title}</Title>
                <DescriptionComponent description={service.description} />
            </ModalContainer>
        </ModalOverlay>
    );
};

export default ServiceDetail;
