import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const FooterWrapper = styled.footer`
  background-color: black;
  color: white;
  width: 100%;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;  
  
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const ContactContenaire = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;


const FooterRight = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-center: center;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 5px;
`;

const Copyright = styled.div`
  background-color: black;
  color: white;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 10px 0;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterLeft>
          <ContactContenaire>
          <StyledLink href="tel:0667362385"><FontAwesomeIcon icon={faPhone} />&nbsp;06 67 36 23 85</StyledLink>
          <StyledLink href="mailto:contact@najet-doula.fr"><FontAwesomeIcon icon={faEnvelope} />&nbsp;contact@najet-doula.fr</StyledLink>
          <StyledLink><FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;Rouen</StyledLink>
          </ContactContenaire>
        </FooterLeft>
        <FooterRight>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20792.56647810679!2d0.9891694290285865!3d49.35080832615446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e11e2bf5448901%3A0xc4a6ab00a298f406!2s76530%20Grand-Couronne!5e0!3m2!1sfr!2sfr!4v1682252747616!5m2!1sfr!2sfr"
            width="100%" height="200px" style={{ border: '0' }} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </FooterRight>
      </FooterContainer>
      <Copyright>© copyright 2023 - Site édité par <a
        href="https://www.linkedin.com/in/s%C3%A9bastien-t-479a85142/">Sébastien Talmant</a></Copyright>
    </FooterWrapper>
  );
}

export default Footer;
