import React from 'react';
import styled from 'styled-components';
import ServicesDashboard from './components/serviceDashboard';
import Footer from '../../footer';

const StyledContainerPage = styled.div`
  display: flex;
  flex-direction: column;  
  width: 100%;
  justify-content; center;
  align-items: center;
`;

function Services() {
    return (
      <>
        <StyledContainerPage>
          <ServicesDashboard/>
          <Footer/>
        </StyledContainerPage>

      </>
    );
}

export default Services;

