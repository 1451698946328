import React from 'react';
import styled from 'styled-components';
import RoleDoula from './components/role';
import Footer from '../../footer';


const StyledContainerPage = styled.div`
  display: flex;
  flex-direction: column;  
  width: 100vw;
  justify-content; center;
  align-items: center;
`;

function Home() {
    return (
      <>
        <StyledContainerPage>
          <RoleDoula/>
          <Footer/>
        </StyledContainerPage>

      </>
    );
}

export default Home;

