import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import idPicture from '../../../pictures/moi.jpg'

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: justify;
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-size: 1.3em; 
`;

const InformationContainer = styled.div`
  width: 60vw;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 7vw;
  margin-top: 3vh;
  font-family: "Abel", sans-serif;
  border-radius: 5px;
  @media(max-width: 767px){    
    width: 97%;
    padding: 5px;
  }
`;

const InformationTitle = styled.h3`
  text-shadow: 3px 3px 10px #f8cfd1;
`;

const StyledParagraphLeft = styled.div`
  opacity: ${props => props.isVisible ? 1 : 0};
  transform: translateX(${props => props.isVisible ? 0 : '-300px'});
  transition: opacity 1s ease, transform 1s ease;
  margin: 20px 0;
  @media(max-width: 767px){    
    opacity: 1;
    transform: translateX(0);
  }
`;

const StyledParagraphRight = styled.div`
  opacity: ${props => props.isVisible ? 1 : 0};
  transform: translateX(${props => props.isVisible ? 0 : '300px'});
  transition: opacity 1s ease, transform 1s ease;  
  @media(max-width: 767px){    
    opacity: 1;
    transform: translateX(0);
  }
`;

const StyledPictureWrapper = styled.div`
  display: flex; 
  width: 100%; 
  justify-content: center;
  align-items: center;
  margin: 50px 0;
`;

const StyledPictures = styled.img`
  border-radius: 50%;
  width: 200px;
  box-shadow: 3px 3px 10px #f8cfd1;
  margin: auto;
  
  @media(max-width: 998px){
    display: none;
  }
`;


const Information = () => {
        const [isVisible1, setIsVisible1] = useState(false);
        const [isVisible2, setIsVisible2] = useState(false);
        const [isVisible3, setIsVisible3] = useState(false);
        const [isVisible4, setIsVisible4] = useState(false);
    
        const containerRef1 = useRef(null);
        const containerRef2 = useRef(null);
        const containerRef3 = useRef(null);
        const containerRef4 = useRef(null);
    
        useEffect(() => {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };
    
            const observer1 = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible1(true);
                    }
                });
            }, options);
    
            const observer2 = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible2(true);
                    }
                });
            }, { ...options, threshold: 0.5 });
    
            const observer3 = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible3(true);
                    }
                });
            }, { ...options, threshold: 0.5 });
    
            const observer4 = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible4(true);
                    }
                });
            }, { ...options, threshold: 0.5 });
    
            if (containerRef1.current) {
                observer1.observe(containerRef1.current);
            }
            if (containerRef2.current) {
                observer2.observe(containerRef2.current);
            }
            if (containerRef3.current) {
                observer3.observe(containerRef3.current);
            }
            if (containerRef4.current) {
                observer4.observe(containerRef4.current);
            }
    
            return () => {
                if (containerRef1.current) {
                    observer1.unobserve(containerRef1.current);
                }
                if (containerRef2.current) {
                    observer2.unobserve(containerRef2.current);
                }
                if (containerRef3.current) {
                    observer3.unobserve(containerRef3.current);
                }
                if (containerRef4.current) {
                    observer4.unobserve(containerRef4.current);
                }
            };
        }, []);
    
        return (
            <SectionWrapper>
                <InformationContainer>
    
                    <InformationTitle>À propos de moi</InformationTitle>
                    <StyledParagraphLeft ref={containerRef1} isVisible={isVisible1}>
                        Je suis Najet, une passionnée de la maternité, mère de cinq enfants. Chaque naissance que j'ai vécue a été une aventure extraordinaire, une expérience unique qui a enrichi ma vie de manière inestimable.
                    </StyledParagraphLeft>
    
                    <StyledParagraphRight ref={containerRef2} isVisible={isVisible2}>
                        Ces moments intenses m'ont façonnée et m'ont donné une profonde compréhension de la puissance et de la beauté de la naissance. C'est cette passion qui m'a naturellement guidée vers le rôle de doula. Je suis convaincue que chaque naissance est une occasion de célébrer la vie et de soutenir les parents dans leur parcours vers la parentalité.
                    </StyledParagraphRight>
    
                    <StyledParagraphLeft ref={containerRef3} isVisible={isVisible3}>
                        En tant que bénévole et ancienne présidente d'une association dédiée à l'allaitement maternel et à l'éducation bienveillante, j'ai acquis une solide expérience dans le soutien aux familles et dans l'écoute attentive de leurs besoins uniques. Chaque rencontre est une nouvelle opportunité d'apprendre, de grandir et de partager.
                    </StyledParagraphLeft>
    
                    <StyledParagraphRight ref={containerRef4} isVisible={isVisible4}>
                        Je suis là pour vous accompagner dans cette aventure extraordinaire. Que vous cherchiez des réponses à vos questions, un soutien émotionnel ou simplement une présence rassurante, je suis là pour vous guider avec bienveillance et compassion. Ensemble, nous pouvons créer un chemin vers une expérience de naissance pleine de confiance, d'amour et d'empowerment. Je suis impatiente de vous rencontrer et de faire partie de votre voyage vers la parentalité.
                    </StyledParagraphRight>
    
                    <StyledPictureWrapper> <StyledPictures src={idPicture} alt="photo identité" /> </StyledPictureWrapper>
    
                </InformationContainer>
            </SectionWrapper>
        );
    };
    
    export default Information;
    