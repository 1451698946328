import React from 'react';
import styled from 'styled-components';
import Information from './components/information';
import Footer from '../../footer';


const StyledContainerPage = styled.div`
  display: flex;
  flex-direction: column;  
  width: 100%;
  justify-content; center;
  align-items: center;
`;

function About() {
    return (
      <>
        <StyledContainerPage>
          <Information/>
          <Footer/>
        </StyledContainerPage>

      </>
    );
}

export default About;
