import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Home from './pages/home/home';
import Services from './pages/services/services';
import Contact from './pages/contact/contact';
import About from './pages/about/about';
import TitlePicture from './titlePicture';
import ServiceDetail from './pages/services/components/serviceDetail';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 990;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  color: white;
  height: 70px;

  @media (min-width: 768px) {
  &.scrolled {  
    background-color: white;
    color: rgb(0, 7, 99);
    border-bottom: 1px solid rgb(0, 7, 99);
    text-shadow: 3px 3px 10px #f8cfd1;
  }
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 75%;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  position: absolute;
  top: 0px;
  right: 0;
  font-family: "Prompt", sans-serif;
  font-size: 1.1rem;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 767px) {
    background-color: white;
    color: rgb(0, 7, 99);
  }

  @media (min-width: 768px) {
    position: static;
    flex-direction: row;
    width: 100vw;
    justify-content: space-around;
    transform: none;
    font-size: 1.1rem;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const NavLink = styled(Link)`
  position: relative;
  margin: 0 30px;
  text-decoration: none;
  font-family: 'signika', sans-serif;
  margin-top: 20px;

  @media (min-width: 768px) {
  color: ${({ scrolled }) => (scrolled ? 'rgb(0, 7, 99)' : 'white')};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: ${({ scrolled }) => (scrolled ? 'rgb(0, 7, 99)' : 'white')};
    transition: width 0.5s;
  }

  &:hover:before {
    width: 100%;
  }

  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;

const BurgerButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  margin-right: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const BurgerLine = styled.div`
  width: 2rem;
  height: 0.25rem;
  background: #14327c;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;

  :first-child {
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
  }

  :nth-child(2) {
    opacity: ${({ open }) => (open ? '0' : '1')};
  }

  :nth-child(3) {
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
  }
`;

function App() {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <>
    <Router>
      <Header className={scrolled ? 'scrolled' : ''}>
        <Link to="/" onClick={closeMenu}></Link>
        <BurgerButton open={open} onClick={() => setOpen(!open)}>
          <BurgerLine open={open} />
          <BurgerLine open={open} />
          <BurgerLine open={open} />
        </BurgerButton>
        <Nav open={open}>
          <NavLink to="/" onClick={closeMenu} scrolled={scrolled}>
            Accueil
          </NavLink>
          <NavLink to="/about" onClick={closeMenu} scrolled={scrolled}>
            À propos de moi
          </NavLink>
          <NavLink to="/services" onClick={closeMenu} scrolled={scrolled}>
            Mes services
          </NavLink>
          <NavLink to="/contact" onClick={closeMenu} scrolled={scrolled}>
            Contacts
          </NavLink>
        </Nav>
      </Header>

      <TitlePicture/>

      <Routes>
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/" element={<Home />} />
        <Route path="/services/:title" element={<ServiceDetail />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;