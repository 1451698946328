import React from 'react';
import styled from 'styled-components';
import ServicesList from './servicesList';
import { Helmet } from 'react-helmet';

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  text-align: justify;
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-size: 1.3em;
`;

const ServicesContainer = styled.div`
  width: 60vw;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 7vw;
  margin-top: 3vh;
  font-family: "Abel", sans-serif;
  border-radius: 5px;
  @media(max-width: 767px){    
    width: 97%;
    padding: 5px;
  }
`;

const ServicesTitle = styled.h3`
  text-shadow: 3px 3px 10px #f8cfd1;
`;

const ServicesDashboard = () => {
    return (
        <SectionWrapper>
            <Helmet>
                <title>Mes services | Najet Doula</title>
                <meta name="description" content="Découvrez les services offerts par Najet Doula à Rouen et Elbeuf, incluant accompagnement prénatal, postnatal, et à la naissance, ainsi que des cérémonies Blessing Way et des soins rituel Rebozo." />
                <meta name="keywords" content="Doula, accompagnement prénatal, postnatal, naissance, Rouen, Elbeuf, Blessing Way, Rebozo, parentalité" />
            </Helmet>
            <ServicesContainer>
                <ServicesTitle>Mes services</ServicesTitle>
                <ServicesList/>
            </ServicesContainer>           
        </SectionWrapper>
    );
};

export default ServicesDashboard;
