import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import banner from '../../../pictures/banner-baby.png'

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  text-align: justify;
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-size: 1.3em;
`;

const RoleDoulaContainer = styled.div`
  width: 60vw;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 7vw;
  margin-top: 3vh;
  font-family: "Abel", sans-serif;
  border-radius: 5px;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(200%)')};
  transition: opacity 0.8s ease, transform 1.2s ease;
  @media(max-width: 767px){    
    width: 97%;
    padding: 5px;
  }
`;

const RoleTitle = styled.h3`
  text-shadow: 3px 3px 10px #f8cfd1;
`;


const StyledSubtitlePictures = styled.img`
  width: 100%;
  opacity: 0.9;
  
  @media(max-width: 998px){
    display: none;
  }
`;


const RoleDoula = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 0);
  }, []);

  return (
    <SectionWrapper>
      <RoleDoulaContainer isVisible={isVisible}>
        <RoleTitle>Le rôle inspirant d'une Doula</RoleTitle>


        <em>"On ne changera pas la vie sans d'abord changer la façon de naître"</em> citation de Michel Odent.
        <p>La doula est une femme qui accompagne, soutient, informe le couple, la femme au moment de la grossesse, de la
          naissance et même après l'accouchement. Lors des rencontres, la doula établit une relation de confiance, de
          complicité et d'intimité de femme à femme. Cela permet à la mère de se sentir en sécurité à chaque étape de sa
          grossesse. La doula peut également avoir un rôle important auprès du futur papa,en devenant une interlocutrice
          privilégiée.</p>

        <p>Avant la naissance, la doula rencontre la ou les futurs parents. La doula n'a pas réponse à tout : elle
          accompagne la démarche des parents dans la recherche d'information. Elle s'informera du projet de naissance des
          parents, et en accord préalable avec l'équipe médicale, la doula pourra vous accompagner durant l'enfantement.
        </p>

        <p>Pendant l'accouchement, la doula soutient moralement les parents, en créant un environnement rassurant, en les
          encourageant ou en s'effaçant pour être présente tout simplement.</p>


        <StyledSubtitlePictures src={banner} alt="bébés" />


        De plus, recourir à une doula pourra vous permettre :
        <ul>
          <li>Un accouchement plus facile</li>
          <li>Une diminution du taux de césarienne de 50%</li>
          <li>Une diminution de 25% de la durée du travail</li>
          <li>Une diminution de 40% de l'utilisation d'ocytociques</li>
          <li>L'utilisation des forceps est réduit de 30%</li>
          <li>6 semaines après la naissance, les mères accompagnées par une doula présente moins de signe d'anxiété et de
            dépression.</li></ul>

        <p>Veuillez noter que les doulas proposent un accompagement émotionnel centré sur la relation d'aide. <strong>Elle
          n'a pas d'approche médiacale
          ni thérapeutique</strong> auprés des femmes/couples. Elles accompagnent les parents qui ont d'ores et déjà un
          suivi médical. Les doulas sont soumises au secret professionel et proposent un accompagnement dans le respect
          des choix des parents, sans discrimination aucune ni jugement.</p>


      </RoleDoulaContainer>


    </SectionWrapper>
  );
};

export default RoleDoula;

