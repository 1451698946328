import React from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import prenat from '../../../pictures/prenatal.png';
import postnat from '../../../pictures/postnatal.png';
import accouchement from '../../../pictures/accouchement.png';
import blessing from '../../../pictures/blessing.png';
import rebozo from '../../../pictures/rebozo.png';
import tente from '../../../pictures/tente.png';
import prenatWebP from '../../../pictures/prenatal.webp';
import postnatWebP from '../../../pictures/postnatal.webp';
import accouchementWebP from '../../../pictures/accouchement.webp';
import blessingWebP from '../../../pictures/blessing.webp';
import rebozoWebP from '../../../pictures/rebozo.webp';
import tenteWebP from '../../../pictures/tente.webp';

const CardContainer = styled.div`
    border: 1px solid #8D99AE;
    border-radius: 4px;
    padding: 16px;
    margin: 16px 0;
    height: 350px;
    box-shadow: 5px 5px 10px #8D99AE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.3em;
    :hover {
        cursor: pointer;
    }
    @media(max-width: 767px){    
        width: 90%;
      }
      @media(max-width: 1350px){   
        height: 300px;  
        font-size: 1.1em;
      }
      @media(max-width: 1000px){   
        height: 250px;
        font-size: 0.8em;
      }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  box-shadow: 3px 3px 10px #f8cfd1;
  margin: 3vh;
  @media(max-width: 1350px){    
    width: 150px;
    height: 150px;
  }
  @media(max-width: 1000px){   
    width: 120px;
    height: 120px;
  }   
  @media(max-width: 767px){    
    width: 200px;
    height: 200px;
  }
`;

const Title = styled.div`
  font-family: "Abel", sans-serif;
`;

const ServiceCard = ({ service }) => {
    const navigate = useNavigate();

    let photo_png;
    let photo_webp;
    let alt_text;

    if (service.title === 'Accompagnement prénatal') {
        photo_png = prenat;
        photo_webp = prenatWebP;
        alt_text = "Image pour l'accompagnement prénatal";
    } else if (service.title === 'Accompagnement postnatal') {
        photo_png = postnat;
        photo_webp = postnatWebP;
        alt_text = "Image pour l'accompagnement postnatal";
    } else if (service.title === 'Accompagnement à la naissance') {
        photo_png = accouchement;
        photo_webp = accouchementWebP;
        alt_text = "Image pour l'accompagnement à la naissance";
    } else if (service.title === 'Blessing way') {
        photo_png = blessing;
        photo_webp = blessingWebP;
        alt_text = "Image pour Blessing way";
    } else if (service.title === 'Cercle de femmes') {
        photo_png = tente;
        photo_webp = tenteWebP;
        alt_text = "Image pour Cercle de femmes";
    } else if (service.title === 'Soin rituel Rebozo') {
        photo_png = rebozo;
        photo_webp = rebozoWebP;
        alt_text = "Image pour Soin rituel Rebozo";
    }

    const goToDetailPage = () => {
        navigate(`/services/${service.title}`);
    };

    return (
        <CardContainer onClick={goToDetailPage}>
            <ImageContainer>
                <picture>
                    <source srcSet={photo_webp} type="image/webp" />
                    <Image src={photo_png} alt={alt_text} />
                </picture>
            </ImageContainer>
            <Title>{service.title}</Title>
        </CardContainer>
    );
};

export default ServiceCard;
