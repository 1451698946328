import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  text-align: justify;
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-size: 1.3em;
  font-familly: "Abel", sans-serif;
`;

const FormContainer = styled.div`
  width: 60vw;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 7vw;
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Abel", sans-serif;
  border-radius: 5px;
  @media(max-width: 767px){    
    width: 97%;
    padding: 5px;
  }
`;

const FormTitle = styled.h3`
  text-shadow: 3px 3px 10px #f8cfd1;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  @media (min-width: 767px) {
    width: 35vw;
  }
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #8D99AE;
  width: 500px;
  @media (max-width: 767px) {
    width: 75vw;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.8em;
`;

const Textarea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #8D99AE;
  width: 500px;
  @media (max-width: 767px) {
    width: 75vw;
  }
`;

const Button = styled.button`
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8cfd1;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  width: 500px;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 767px) {
    width: 75vw;
  }
`;

const ContactUs = () => {
    const form = useRef();  
    const [errors, setErrors] = useState({});

    const validateForm = (values) => {
      const errors = {};
    
      if (!values.name) {
        errors.name = 'Veuillez entrer votre nom';
      }
      if (!values.surname) {
        errors.surname = 'Veuillez entrer votre prénom';
      }
      if (!values.telephone || !/^\d{10}$/.test(values.telephone)) {
        errors.telephone = 'Veuillez entrer un numéro de téléphone valide (10 chiffres)';
      }
      if (!values.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
        errors.email = 'Veuillez entrer une adresse email valide';
      }
      if (!values.message) {
        errors.message = 'Veuillez entrer votre message';
      }
    
      setErrors(errors);
      return Object.keys(errors).length ===0;
    };
  

    const sendEmail = (e) => {
        e.preventDefault();
        const formData = new FormData(form.current);
        const values = Object.fromEntries(formData.entries());


        if(validateForm(values)){
        emailjs.sendForm('service_rcrbugr', 'template_k07vcpk', form.current, 'LHT6r6aH0K0UZiBWK')
            .then((result) => {
                console.log(result.text);
                alert('Votre message a bien été envoyé !');
                form.current.reset();
            }, (error) => {
                console.log(error.text);
                alert('Une erreur est survenue lors de l\'envoi de votre message.');
            });}
    };
    return (
        <SectionWrapper>
            <FormContainer>
                <FormTitle>Me contacter</FormTitle>
                <Form ref={form} onSubmit={sendEmail}>
                    <div className="g-recaptcha" data-sitekey="6LcTSd4pAAAAAMK2LeKiPm8Cdd4IcpdxyNn9OyfO"></div>
                    <Input type="text" name="name" placeholder="Votre nom" required />
                    {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                    <Input type="text" name="surname" placeholder="Votre prénom" required />
                    {errors.surname && <ErrorMessage>{errors.surname}</ErrorMessage>}
                    <Input type="text" name="telephone" placeholder="N° téléphone" required />
                    {errors.telephone && <ErrorMessage>{errors.telephone}</ErrorMessage>}
                    <Input type="text" name="email" placeholder="Votre email" required />
                    {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                    <Textarea rows="5" name="message" placeholder="Votre message" required />
                    {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
                    

                    <Button type="submit">Envoyer</Button>
                </Form>
            </FormContainer>
        </SectionWrapper>
    );
};

export default ContactUs;

