import React from 'react';
import styled from 'styled-components';
import ContactUs from './component/contactForm';
import Footer from '../../footer';


const StyledContainerPage = styled.div`
display: flex;
flex-direction: column;  
width: 100%;
justify-content; center;
align-items: center;
`;

function Contact() {
    return (
        <StyledContainerPage>
          <ContactUs/>
          <Footer/>
        </StyledContainerPage>
    );
}

export default Contact;

