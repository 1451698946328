import React, { useState, useEffect } from 'react';
import ServiceCard from './serviceCard';
import styled from 'styled-components';
import servicesData from '../../../servicesData.json';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ServicesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
`;

const ServicesList = () => {
    const [services, setServices] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setServices(servicesData);
        const mediaQuery = window.matchMedia("(min-width: 767px)");
        setIsMobile(mediaQuery.matches);

        const handleChange = (e) => {
            setIsMobile(e.matches);
        };

        mediaQuery.addEventListener('change', handleChange);


        return () => {
            mediaQuery.removeEventListener('change', handleChange);
        };
    }, []);

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    background: "lightgrey",
                    height: "40px",
                    width: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "999",
                }}
                onClick={onClick}
            >
            </div>
        );
    }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{
                ...style,
                display: "block",
                background: "lightgrey",
                height: "40px",
                width: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "999",
            }}
            onClick={onClick}
          />
        );
      }

    const settings = {
        dots: true,
        pauseOnHover: true,
        infinite: true,
        speed: 2000,
        centerPadding: "60px",
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    

    return (
        <div>
            {isMobile ? (
                <Slider {...settings}>
                    {services.map((service, index) => (
                        <ServiceCard key={index} service={service} />
                    ))}
                </Slider>
            ) : (
                <ServicesGrid>
                    {services.map((service, index) => (
                        <ServiceCard key={index} service={service} />
                    ))}
                </ServicesGrid>
            )}
        </div>
    );
};


export default ServicesList;
