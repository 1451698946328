import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import banner from './pictures/banner.png';


const StyledContainerTitle = styled.div`
  width: 100vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 50% 50% / 0 0 25% 25%;
  overflow: hidden;
  background-color: #c1e1d9;
  font-family: "Prompt", sans-serif;
  text-shadow: 3px 3px 10px #f8cfd1;
 
  @media(max-width: 767px){
    height: 35vh;
    border-radius: 0 0 0 0 / 0 0 0 0;
  }
`;

const StyledSubtitlePictures = styled.img`
  z-index: -1;
  min-width: 100vw;
  height: 110%;  
  opacity: 0.2;
  
  @media(max-width: 998px){
    display: none;
  }
`;

const StyledTitleWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  height: 50vh;
  margin-bottom: 20px;
  @media(max-width: 767px){    
    height: 35vh;
  }
`;


const StyledH1 = styled.h1`
  font-size: 4em;
  margin-bottom: 0.2em;
  position: absolute;
  bottom: 50%;
  width: 100vw;
  opacity: ${props => props.show ? 1 : 0};  
  transform: ${props => props.show ? 'translateY(0)' : 'translateY(-100%)'};
  transition: opacity 0.6s ease, transform 0.6s ease;

  @media(max-width: 767px){    
    font-size: 2rem;
  }
`;

const StyledH1Bis = styled.h1`
  font-size: 2em;
  font-weight: normal;
  position: absolute;
  bottom: 35%;
  width: 100vw;
  opacity: ${props => props.show ? 1 : 0};  
  transform: ${props => props.show ? 'translateX(0)' : 'translateX(-200%)'};
  transition: opacity 0.8s ease, transform 0.8s ease;

  @media(max-width: 767px){    
    font-size: 1rem;
  }
`;


function TitlePicture() {
  const [showTitle, setShowTitle] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowTitle(true);
    }, 500);

  }, []);

  return (
    <StyledContainerTitle>
      <StyledTitleWrapper>
        <StyledSubtitlePictures src={banner} alt="femmes enceintes" />
        <StyledH1 show={showTitle}>Najet Doula Rouen</StyledH1>
        <StyledH1Bis show={showTitle}>Accompagnante à la naissance</StyledH1Bis>
      </StyledTitleWrapper>
    </StyledContainerTitle>
  );
}

export default TitlePicture;

